
import { defineComponent } from "vue";
import AdminPanelNavMenu from "@/components/molecules/AdminPanelNavMenu.vue";
import Pluse from "@/assets/svg/pluse.svg?inline";
import View from "@/assets/svg/view.svg?inline";
import Edit from "@/assets/svg/edit.svg?inline";
import Delete from "@/assets/svg/delete.svg?inline";
import Search from "@/assets/svg/search.svg?inline";
import DefaultPopup from "@/components/molecules/DefaultPopup.vue";
import Pagination from "@/components/molecules/Pagination.vue";
import SortArrow from "@/assets/svg/sort-arrow.svg?inline";

export default defineComponent({
  name: "Stickers",
  data() {
    return {
      page: 1,
      stickersData: {},
      stickersMeta: null,
      errorstickers: null,
      isModalDelete: false,
      searchData: "",
      sortData: "",
      stickerId: null,
    };
  },
  components: {
    AdminPanelNavMenu,
    Pluse,
    View,
    Edit,
    Delete,
    Search,
    DefaultPopup,
    Pagination,
    SortArrow,
  },
  watch: {
    searchData() {
      this.getStickers();
      this.page = 1;
    },
  },
  mounted() {
    this.getStickers();
  },
  methods: {
    createSticker() {
      this.$router.push("/admin/create-sticker");
    },
    getStickers(params, sort) {
      let searchParam = `?page=${this.page}&search=`;
      if (this.searchData !== "") {
        searchParam = `?page=${this.page}&search=` + this.searchData;
      }
      if (sort === undefined) {
        sort = "id";
      }
      params = searchParam + "&sort=" + sort;
      this.$store.dispatch("stickers/getStickers", params).then(
        (res) => {
          this.stickersData = res.data;
          this.stickersMeta = res.meta;
        },
        (error) => {
          this.errorInstructors = error.response.data.errors;
        }
      );
    },
    viewSticker(id) {
      this.$router.push("/admin/sticker?sticker=" + id);
    },
    editSticker(id) {
      this.$router.push("/admin/create-sticker?edit=true&sticker=" + id);
    },
    openDeletePoup(id) {
      this.stickerId = id;
      this.isModalDelete = true;
    },
    handleCloseModalDelete() {
      this.isModalDelete = false;
    },
    deleteSticker() {
      this.$store.dispatch("stickers/deleteSticker", this.stickerId).then(
        (res) => {
          this.page = 1;
          this.isModalDelete = false;
          this.getStickers();
        },
        (error) => {
          this.errorInstructors = error.response.data.errors;
        }
      );
    },
    handlePage(page) {
      this.page = page;
      this.getStickers();
    },
  },
});
